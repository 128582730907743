import * as qiniu from 'qiniu-js'
import Vue from "vue"
import { USER_INFO } from "@/store/mutation-types"
import { clientUploadToken, getBasedHaskeyTradeUrl } from "@/api/common"
const defaultSettings = require("@/config/index.js");
/**
 * 生成页面标题
 */
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle}${defaultSettings.title}`
  }
  return `${defaultSettings.title}`
}

/**
 * 判断是否为外部资源
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}



// 封装上传图片的方法
export function uploadImageWithAntiLeech(file) {

  return new Promise((resolve, reject) => {

    console.log(file, 'file');

    clientUploadToken().then(res => {
      var config = {
        useCdnDomain: true,
        region: qiniu.region.z2 //区域
      };

      // 设置防盗链参数
      var putExtra = {
        fname: file.name, // 文件原文件名
        params: {
          "save-key": "$(etag)",
          // "referer": `http://192.168.0.251:8081`  // 设置防盗链referer参数
        }
      };

      var key = 'stuEnroll/' + new Date().getTime() + file.name;

      // 执行上传
      var observable = qiniu.upload(file, key, res.result);

      console.log(observable, 'observable');


      observable.subscribe({
        next(res) {
        },
        error(err) {
          reject(err)
        },
        complete(res) {
          let userInfo = Vue.ls.get(USER_INFO);
          let params = {
            createUserId: userInfo.id,
            createUserName: userInfo.name || 'xxx',
            size: file.size,
            name: file.name,
            resourceHash: res.hash,
            resourceKey: res.key,
          }
          getBasedHaskeyTradeUrl(params).then(res => {
            resolve(res)
          })
        }
      });

    })
  })
}


export function variableJudge(obj, keyName, tag = '?.') {
  if (!obj) return undefined
  let keys = keyName.split(tag)
  return keys.reduce((objNew, keyItem) => {
    if (keyItem === '') return objNew
    if (keyItem.indexOf('.') !== -1) return variableJudge1(objNew, keyItem, '.')
    return objNew?.[keyItem]
  }, obj)
}

// 获取对象中某个属性的值
export function findPropertyInObject(obj, prop) {  
  let result = null;  
  
  function search(o) {  
    if (o.hasOwnProperty(prop)) {  
      result = o[prop];  
    }  
    for (let key in o) {  
      if (o.hasOwnProperty(key) && typeof o[key] === 'object' && o[key] !== null) {  
        search(o[key]);  
      }  
    }  
  }  
  
  search(obj);  
  return result;  
} 


/**
 * 日期格式化
 */
export const handleTime=(date, format)=> {
	format = format || 'yyyy-MM-dd hh:mm:ss'
	date = new Date(date)
  
	if (date !== 'Invalid Date') {
	  let o = {
		'M+': date.getMonth() + 1, //month
		'd+': date.getDate(), //day
		'h+': date.getHours(), //hour
		'm+': date.getMinutes(), //minute
		's+': date.getSeconds(), //second
		'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
		S: date.getMilliseconds() //millisecond
	  }
	  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	  for (let k in o)
		if (new RegExp('(' + k + ')').test(format))
		  format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
	  return format
	}
	return ''
  }

    //json合并
export const appendDestjsonFromsrcjson = (srcArr, destArr) => {
  for (let i in srcArr) {
    destArr[i] = srcArr[i]
  }
}

//根据valve过滤出相对应的数组
export const getmaparrList = (arrlist, vname, value, flag = true) => {
	return arrlist.filter((item) => (flag ? item[vname] == value : item[vname] != value))
}
