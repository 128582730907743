import LoginEnter from "@/views/loginEnter";

/**
 * @abstract 拼接路由参数  '/:id' + url
 */
export const p = (url) => '/:id' + url;


// 首页导航栏路由
export const navigationRouterMap = [
    {
        path: p('/home'),
        redirect: p('/home/infoCollection')
    },
    {
        path: p('/home/infoCollection'),
        name: "infoCollection",
        component: () => import('@/views/infoCollection'),
        meta: {
            title: "学生报名信息",
            type: 'navtion',
            path: '/home/infoCollection'
        },
    },
    {
        path: p('/home/auditQuery'),
        name: "auditQuery",
        component: () => import('@/views/auditQuery'),
        meta: {
            title: "审核结果查询",
            type: 'navtion',
            path: '/home/auditQuery'
        },
    },
    {
        path: p('/home/examcontent'),
        name: "examcontent",
        component: () => import('@/views/examcontent'),
        meta: {
            title: "考试专区",
            type: 'navtion',
            path: '/home/examcontent'
        },
    },
    {
        path: p('/home/procalamation'),
        name: "procalamation",
        component: () => import('@/views/procalamation'),
        meta: {
            title: "招生公告",
            type: 'navtion',
            path: '/home/procalamation'
        },
    },
    {
        path: p('/home/guide'),
        name: "guide",
        component: () => import('@/views/guide'),
        meta: {
            title: "报名指南",
            type: 'navtion',
            path: '/home/guide'
        },
    }
]


/**
 * constantRouterMap
 * 没有权限要求的基本页
 * 所有角色都可以访问
 * 不需要动态判断权限的路由
 */
export const constantRouterMap = [
    {
        path: "/",
        redirect: '/404'
    },
    {
        path: p('/login'),
        name: "login",
        component: LoginEnter, //登录入口文件
        meta: {
            title: "登录",
        },
    },
    {
        path: p('/home'),
        name: "home",
        component: () => import('@/components/Layouts/index'),
        children: navigationRouterMap
    },
    {
        path: "/404",
        name: "404",
        component: () => import('@/views/exception/404'),
    },
    { path: '*', redirect: '/404' }
];

/**
 * 动态菜单，走权限控制
 *
 * 可从后端获取
 * 可分多个角色
 * 分为三个角色 管理员admin  普通角色 visitor
 */
export const asyncRouterMap = [
    // {
    //     path: "/onlyAdminUserCanSee",
    //     name: "adminPage",
    //     component: () => import('@/views/AdminPage'),
    //     meta: {
    //         title: "管理员页面",
    //         keepalive: false,
    //         roles: ["admin"],
    //     },
    //     props: route => ({ msg: route.query.msg })
    // },
    // {
    //     path: "/onlyLoginUserCanSee",
    //     name: "visitPage",
    //     component: () => import('@/views/VisitorPage'),
    //     meta: {
    //         title: "用户界面",
    //         keepalive: false,
    //         roles: ["admin", "visitor"],
    //     },
    //     props: route => ({ msg: route.query.msg })
    // },
    // { path: '*', redirect: '/404', component: () => import('@/views/exception/404') }
];
